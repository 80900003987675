:root {
  --amplify-primary-color: rgba(0, 0, 0, .25);
  --amplify-primary-tint: rgba(0, 0, 0, .25);
  --amplify-primary-shade: rgba(0, 0, 0, .25);
}

body {
  font-size: .875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
  font-size: .875rem;
  text-align: left;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #2470dc;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar-brand {
  text-align: center;  
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, .25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .navbar-toggler {
  top: .25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.dropzone {
  width : 100%;
  height : 200px;
  border : 3px dashed rgba(0, 0, 0, .25);
  background-color: rgba(184, 184, 184, 0.1);
}
.dropzone-text {
  margin-left: auto;
  margin-right: auto;
  width: 32em;
  padding: 80px 0;
}

span.active {
  color: rgb(52, 136, 84);
}

tr.clickable {
  cursor: pointer;
}
td.clickable {
  cursor: pointer;
}


td.validationError {
  color:rgb(189, 40, 20);
  font-weight: bolder;
}

td.timesheet-firstColumn {
  width: 30%;
}

input.timesheet-textbox {
  cursor: pointer;
  background-color: white;
}
table.timesheet-table {
  width: 800px;
}

th.timesheet-topleft {
  vertical-align: middle;
}
/* and (-webkit-min-device-width:1000px) */
@media only screen and (-webkit-min-device-pixel-ratio: 2)
{
  div.chart-billables {
    width: 664px;
  }
  div.chart-budgets {
    width: 664px;
  }
  div.chart-estimates {
    text-align:center;
    width: 650px;
  }
}

@media only screen and (-webkit-max-device-pixel-ratio: 1) {
  div.chart-billables {
    width: 700px;
  }
  div.chart-budgets {
    width: 700px;
  }
  div.chart-estimates {
    text-align:center;
    width: 750px;
  }
}


canvas.chart-estimates {
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 400px;
  height: 400px;
}

td.document-description {
  width: 40%;
}

td.text-green {
  color: rgb(74, 131, 96);
}
.delete-entry-wrapper {
    position: relative;
    min-height: 30px;
    min-width: 100px;
}
.delete-entry {
  font-size: .750rem !important;
}
.chart-billables-buttongroup {
  margin-right:auto;
  margin-left:auto;
  padding-left:15px;
  padding-right:15px;
  text-align:center;
  align-content:center;

}

/* progress bar */
.progress-sm {
  height: 0.5rem!important;
  background-color: rgba(255, 255, 255, 0.788)!important;
  border: 1px solid rgba(161, 155, 155, 0.301);
}
.progress-lg {
  height: 1rem!important;
  background-color: rgba(255, 255, 255, 0.788)!important;
  border: 1px solid rgba(161, 155, 155, 0.301);
}

td.timesheet-totals {
  font-weight: bolder;
  text-align: center; 
  vertical-align: middle;
}

td.timesheet-totals-disabled, td.timesheet-entry-disabled {
  background: repeating-linear-gradient(
  45deg,
    #e7ebee,
    #e7ebee 10px,
    #dfdfe2 10px,
    #dfdfe2 20px
  );
}

td.border-top {
  border-top: 1px dotted rgba(116, 116, 116, 0.548)!important;
}

button.action {
  background-color: white;
}

.topnumbers-border-right {
  border-right: 1px dashed #b3b3b3;
}
.topnumbers-border-right-2x {
  border-right: 1px solid #888787;
}

.calendar-overlay {
  position: absolute;
  padding: 0;
  right: 20px;
  height: 400px;
  background-color: #ffffff;

}

td.timesheet-approval {
  text-align: right;
  background-color: transparent !important;
  padding-right: 20px;
}
tr.timesheet-approval {
  --bs-table-striped-bg: transparent;
  --bs-table-hover-bg: transparent;
}

div.timesheet-approved {
  font-weight: bolder;
  font-size: larger;
  color:rgb(52, 136, 84);
}

span.timesheet-waiting-for-approval {
  font-size: smaller;
}
td.background-transparent {
  background-color: transparent !important;
}
td.background-timeoff-selected {
  background-color: #3759ad !important;
}
td.background-timeoff, td.background-timeoff-100 {
  background-color: rgb(162, 96, 175) !important;
}
td.background-timeoff-75 {
  background: linear-gradient(to right bottom, rgb(162, 96, 175) 48%, white 50%) !important;
}
td.background-timeoff-50 {
  background: linear-gradient(to right bottom, rgb(162, 96, 175) 48%, white 50%) !important;
}
td.background-timeoff-37 {
  background: linear-gradient(to right bottom, rgb(162, 96, 175) 33%, white 35%) !important;
}
td.background-timeoff-25 {
  background: linear-gradient(to right bottom, rgb(162, 96, 175) 24%, white 25%) !important;
}
td.background-timeoff-12 {
  background: linear-gradient(to right bottom, rgb(162, 96, 175) 12%, white 14%) !important;
}
td.background-active {
  background-color: rgb(78, 88, 134) !important;
}
td.background-active-2 {
  background-color: rgb(113, 126, 184) !important;
}

td.background-weekend-transparent {
  background-color: #dadada !important;
}
td.background-weekend-active {
  background-color: rgb(95, 99, 119) !important;
}
td.background-weekend-active-2 {
  background-color: rgb(116, 121, 146) !important;
}


div.dropdown-planning {
  position: absolute;
  display: block;
  background-color: white;
  border: 1px solid;
  text-align: left;
  max-height: 350px;
  overflow-y: auto;
}

div.planning-legend-footer-element {
  font-size: small;
  color: #6e6e6e;
  display: block;
  height: 40px;
}
div.planning-legend-footer {
  position:fixed;
  bottom:0px;
  height:140px;   /* Height of the footer */
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid black;
  margin-right: 15px;
}

